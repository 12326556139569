<template>
  <Page :classes="['page--vcenter']">

    <div class="auth" v-if="isProcessing">
      <div class="auth__header">
        <h1 class="auth__title">{{$t('auth.activating_account')}}</h1>
      </div>
      <Spinner :classes="['spinner--center']"></Spinner>
    </div>

    <div class="auth" v-if="!isProcessing">
      <div class="auth__header">
        <h1 class="auth__title" v-if="isSuccess">{{$t('auth.account_activated')}}</h1>
      </div>

      <p class="auth__tip" v-if="isSuccess === false">
        {{$t('error.contact_admin')}}
      </p>

      <div class="auth__link" v-else>
        <p>{{$t('common.redirecting')}}</p>
        <Spinner :classes="['spinner--center']"></Spinner>
      </div>
    </div>

  </Page>
</template>

<script>
export default {
  name: 'ActivateAccount',
  data() {
    return {
      isProcessing: true,
      isSuccess: undefined,
    };
  },
  computed: {
    hasQueryParams() {
      return this.$route.query && this.$route.query.uidb64 && this.$route.query.token;
    },
  },
  created() {
    if (!this.hasQueryParams) {
      this.$router.push({ name: 'login', params: { locale: this.$i18n.locale } });
      return;
    }
    this.$store.dispatch('activateUser', {
      uidb: this.$route.query.uidb64,
      token: this.$route.query.token,
    }).then(() => {
      this.isSuccess = true;
      setTimeout(() => {
        this.$router.push({ name: 'new-entity', params: { locale: this.$i18n.locale } });
      }, 1000);
    }).catch(() => {
      this.isSuccess = false;
    }).finally(() => {
      this.isProcessing = false;
    });
  },
};
</script>
